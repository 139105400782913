export default {
  development: {
    APIDEV: "https://localhost:5001/api/",
    API: "https://plugfest-2021-dev.azurewebsites.net/api/", // https://plugfest-2021-prod.azurewebsites.net/api/
    API2023: "https://plugfest-2022-dev.azurewebsites.net/api/", // http://localhost:5176/api/ https://plugfest-2022-prod.azurewebsites.net/api/
  },
  production: {
    API: "https://plugfest-2021-prod.azurewebsites.net/api/", // https://plugfest-2021-dev.azurewebsites.net/api/  https://plugfest-2021-prod.azurewebsites.net/api/
    API2023: "https://plugfest-2022-prod.azurewebsites.net/api/", // https://plugfest-2022-dev.azurewebsites.net/api/  https://plugfest-2022-prod.azurewebsites.net/api/
  },
};
