<template>
  <div class="page-header-home">
    <div class="col icon">
      <img alt="O-RAN logo" src="@/assets/logo_plugfest_blue.svg" />
    </div>
    <div class="col text">
      <div class="row header-wrapper">
        <div class="col text-title">O-RAN Global PlugFest</div>
        <div class="col year-switch">
          <img class="switcher-background" src="@/assets/year-changer.svg" />
          <div class="years-dropdown" v-if="yearsDropdownShow">
            <div @click="onYearSelect(2020)">2020</div>
            <div @click="onYearSelect(2021)">2021</div>
            <div @click="onYearSelect(2022)">2022</div>
            <div @click="onYearSelect(2023)">2023</div>
            <div @click="onYearSelect(2024)">2024</div>
          </div>
          <div class="year-current" @click="onYearDropdownVisibility">
            {{ currentYear }}
          </div>
        </div>
        <div class="col seasons" v-if="currentYear == '2022'">
          <div class="row" @click="onSeasonSelect('SPRING')">
            <template v-if="season == 'SPRING'"
              ><img class="season" src="@/assets/season-selected.svg" /><span
                >Spring</span
              ></template
            >
            <template v-else
              ><img class="season" src="@/assets/season.svg" /><span
                >Spring</span
              ></template
            >
          </div>
          <div class="row" @click="onSeasonSelect('FALL')">
            <template v-if="season == 'FALL'"
              ><img class="season" src="@/assets/season-selected.svg" /><span
                >Fall</span
              ></template
            >
            <template v-else
              ><img class="season" src="@/assets/season.svg" /><span
                >Fall</span
              ></template
            >
          </div>
        </div>
        <div class="col seasons" v-if="currentYear == '2023'">
          <div class="row" @click="onSeasonSelect('SPRING')">
            <template v-if="season == 'SPRING'"
              ><img class="season" src="@/assets/season-selected.svg" /><span
                >Spring</span
              ></template
            >
            <template v-else
              ><img class="season" src="@/assets/season.svg" /><span
                >Spring</span
              ></template
            >
          </div>

          <div class="row" @click="onSeasonSelect('FALL')">
            <template v-if="season == 'FALL'"
              ><img class="season" src="@/assets/season-selected.svg" /><span
                >Fall</span
              ></template
            >
            <template v-else
              ><img class="season" src="@/assets/season.svg" /><span
                >Fall</span
              ></template
            >
          </div>
        </div>

        <div class="col seasons" v-if="currentYear == '2024'">
          <div class="row" @click="onSeasonSelect('SPRING')">
            <template v-if="season == 'SPRING'"
              ><img class="season" src="@/assets/season-selected.svg" /><span
                >Spring</span
              ></template
            >
            <template v-else
              ><img class="season" src="@/assets/season.svg" /><span
                >Spring</span
              ></template
            >
          </div>

          <div class="row" @click="onSeasonSelect('FALL')">
            <template v-if="season == 'FALL'"
              ><img class="season" src="@/assets/season-selected.svg" /><span
                >FALL</span
              ></template
            >
            <template v-else
              ><img class="season" src="@/assets/season.svg" /><span
                >FALL</span
              ></template
            >
          </div>
        </div>
      </div>
      <div class="text-description">
        O-RAN PlugFests support the ecosystem players in testing and
        integration<br />of their implementations, ensuring the openness and
        interoperability of O-RAN solutions from different providers
      </div>
    </div>
  </div>
</template>

<script src="./PageHeaderHome.js"></script>
