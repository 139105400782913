<template>
  <div class="page home">
    <div class="venues-background"></div>
    <PageHeader />
    <div v-if="slideDetailShow" id="slide-detail">
      <img src="@/assets/close2.svg" id="close-modal-button" @click="onClick" />
      <div class="slide-detail-wrapper">
        <div class="slide-detail-content">
          <template v-if="$route.params.season == 'SPRING'">
            <p>
              For the first time, O-RAN PlugFest focused on themes prioritized
              within O-RAN ALLIANCE.
            </p>

            <p>
              PlugFest themes help to aim global efforts in O-RAN testing and
              integration to increase efficiency and speed up technology
              development cycle.
            </p>

            <p>
              Participants at the Spring 2024 PlugFest achieved improvements in
              areas of RAN energy consumption resulting in TCO optimization,
              efficiency of O-RAN deployments, and broadening the offer of open
              and intelligent RAN products on the market
            </p>

            <p>
              The Spring 2024 PlugFest delivered achievements in five themes:
            </p>

            <ul>
              <li>
                O-RAN Energy Consumption, Efficiency and Savings Testing:
                Participating labs successfully tested O-RAN energy saving use
                cases in different network conditions, helping to optimize RAN
                Total Cost of Ownership (TCO) and overall sustainability of
                mobile network operations.
              </li>
              <li>
                Demonstrate consistent and repeatable open fronthaul testing in
                multiple labs: Several PlugFest hosts partnered to prove the
                ability to achieve consistent testing results in independent
                labs, ensuring testing reliability across labs and regions and
                supporting certification of O-RAN based products.
              </li>
              <li>
                Open Fronthaul Transport Testing with multiple O-RUs: Building
                on previous O-RAN PlugFests, hosts and participants of the
                Spring 2024 PlugFest successfully extended the number of
                multi-vendor interoperable configurations in different setups
                and conditions, broadening the offer of O-RAN solutions for
                mobile operators.
              </li>
              <li>
                O-RAN System Testing with Layer 1 Acceleration: The PlugFest
                verified readiness to automatically deploy and configure Layer 1
                accelerators to allow their efficient testing in different
                network configurations.
              </li>
              <li>
                O-RAN E2E Deployment Templates, DevOps, and Test Automation:
                Focusing on increasing efficiency of O-RAN network deployments,
                PlugFest participants demonstrated automation of testing in
                different areas including testbed setup, security testing,
                rApps/xApps and RIC testing, utilizing CI/CD processes, and
                application of the same automation in different labs.
              </li>
            </ul>
          </template>
          <template v-if="$route.params.season == 'FALL'">
            <p>
              O-RAN ALLIANCE co-sponsors semi-annual Global PlugFests, hosted by
              operators, Open Testing and Integration Centres (OTICs), and
              academic and research institutions, to enable efficient progress
              of developing products and solutions based on O-RAN
              specifications. Any company with O-RAN membership is free to
              participate and benefit from well organized testing and
              integration in a neutral, cooperative environment. Efforts at
              O-RAN PlugFests are prioritized in themes to maintain focus on key
              areas.
            </p>
            <p>
              Each O-RAN PlugFest advances the development of innovative O-RAN
              based technologies and solutions. Participants at the O-RAN Global
              PlugFest Fall 2024 demonstrated progress in diverse areas,
              including:
            </p>
            <ul>
              <li>
                Performance improvements of open and interoperable RAN in
                combination with intelligent network management functions, based
                on Near-Real-Time and Non-Real-Time RAN Intelligent Controllers
                (RIC)
              </li>
              <li>
                RIC solutions newly developed by providers, operators and
                academic institutions
              </li>
              <li>
                Network energy savings measured according to ETSI energy
                efficiency test specifications
              </li>
              <li>Using open source software to deliver RAN functions</li>
              <li>
                Test automation and delivery of repeatable results consistent
                across different test labs
              </li>
              <li>
                Point-to-Multipoint Open Fronthaul transport for reducing
                network power consumption and the number of required devices
              </li>
              <li>
                Multi-operator Service Management and Orchestration (SMO) to
                enhance adaptability, security, and resilience of the RAN for
                public safety and peacekeeping missions
              </li>
              <li>
                O-RAN conformance and interoperability testing of products from
                an increasing number of providers
              </li>
            </ul>
          </template>
        </div>
      </div>
    </div>
    <div class="card-wrapper">
      <div class="col card" @click="onShowHideSlideDetail">
        <swiper
          :modules="modules"
          :slides-per-view="1"
          :space-between="80"
          @swiper="onSwiper"
          @slideChange="onSlideChange"
        >
          <swiper-slide>
            <div class="card-wrapper">
              <div class="card-content">
                <div class="card-image">
                  <template v-if="$route.params.season == 'SPRING'">
                    <img alt="News" src="@/assets/news_01.jpg" />
                  </template>
                  <template v-if="$route.params.season == 'FALL'">
                    <img alt="News" src="@/assets/news_02.png" />
                  </template>
                </div>

                <div class="card-title"></div>
                <div class="card-title-text">
                  <template v-if="$route.params.season == 'SPRING'">
                    O-RAN Global PlugFest Spring 2024, co-hosted by 20
                    operators, OTICs, and academic and research institutions.
                  </template>
                  <template v-if="$route.params.season == 'FALL'">
                    O-RAN Global PlugFest Fall 2024, co-hosted by 29 operators,
                    OTICs, and academic and research institutions.
                  </template>
                </div>
                <div class="card-description">
                  <div>
                    <template v-if="$route.params.season == 'SPRING'">
                      O-RAN Global PlugFest Spring 2024 was conducted from
                      February to May 2024 in 18 labs across Asia, Europe, and
                      North America. The PlugFest had 86 participating companies
                      or institutions, some of which participated in more than
                      one venue.
                    </template>
                    <template v-if="$route.params.season == 'FALL'">
                      O-RAN Global PlugFest Fall 2024 was conducted from July to
                      November 2024 in 28 labs across Asia, Europe, and North
                      America. The PlugFest had 115 participating companies or
                      institutions, some of which participated in more than one
                      venue.
                    </template>
                  </div>
                </div>
                <div class="card-read-more">
                  <template v-if="!slideDetailShow"
                    ><div>Read more</div></template
                  >
                  <template v-else><div>Close</div></template>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="col venues-items">
        <Venues
          :data="venues"
          :showSwitch="true"
          title="Select PlugFest venue to explore"
          :showScrenariosCombo="false"
        />
      </div>
    </div>

    <Participants
      v-if="alphabet"
      :data="alphabet"
      title="O-RAN Global PlugFest Spring/Fall 2024 Participants"
    />
    <Footer backgroundDirection="left" />
  </div>
</template>

<script src="./Home2024.js"></script>
